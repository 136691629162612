import React from 'react';
import { Typography, Box } from '@material-ui/core';
import Layout from '../../../components/layout';
function RSVPYESSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
          <Typography align='center' variant='body1' gutterBottom>
            Thank you for confirming your attendance to the fourth edition of the
          </Typography>
          <Typography align='center' variant='body1' gutterBottom>
            Global Manufacturing & Industrialisation Summit (#GMIS2021).
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            We look forward to welcoming you at the GMIS Week from
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            22-27 November 2021 at EXPO’s Dubai Exhibition Centre.
          </Typography>
        </Box>
        <img width='100%' src='../images/gmis-static-banner.png' />
      </Box>
    </Layout>
  );
}
export default RSVPYESSuccess;
